import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { queryStringToObject, redirectIfMaintenanceMode } from 'helpers';
import { useAuthToken } from 'hooks';
import { Icon, ICON_NAMES } from 'icons';
import { useRouter } from 'next/router';
import {
  API_USERS_ME,
  APP_HOME,
  OPS_HOME,
  UserRole,
  WEB_APP_ROOT,
} from 'types';
import { getDefaultServerHeaders } from 'utils/helpers/auth';
import { useRedirect } from 'utils/hooks/useRedirect';

const fetchLogin = async (email: string, password: string) => {
  const response =  await fetch(`${process.env.NEXT_PUBLIC_API_ROOT}/auth/token/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email.toLowerCase(),
      password,
    }),
  }).then(redirectIfMaintenanceMode);

  return response;
};

const fetchUser = async (token: string) => {
  return await fetch(API_USERS_ME, {
    method: 'GET',
    headers: getDefaultServerHeaders(token),
  });
};

export const LoginForm: FC = () => {
  const router = useRouter();
  const { register, handleSubmit, formState, setValue, clearErrors } =
    useForm();

  const { errors } = formState;

  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [formDimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });
  const formRef = useRef<HTMLFormElement>();
  const [showPassword, setShowPassword] = useState(false);
  const { redirectUrl } = useRedirect();
  const { setAuthToken } = useAuthToken();

  const redirectToApp = useCallback(() => {
    window.location.href = `${WEB_APP_ROOT}${APP_HOME}`;
  }, []);

  useEffect(() => {
    if (!formRef || !formRef.current) return null;
    setDimensions({
      height: formRef?.current?.getBoundingClientRect().height ?? 48,
      width: formRef?.current?.getBoundingClientRect().width ?? 200,
    });
  }, [formRef]);

  const onSubmit = async formData => {
    const { email, password } = formData;
    setIsProcessing(true);
    try {
      const authResponse = await fetchLogin(email, password);
      redirectIfMaintenanceMode(authResponse);
      const { auth_token } = await authResponse.json();
      if (!auth_token) {
        setLoginError("Didn't work. Try different details?");
        setIsProcessing(false);
        return;
      }

      setAuthToken(auth_token);

      const userResponse = await fetchUser(auth_token);
      const { user_type } = await userResponse.json();

      if (!user_type || user_type !== UserRole.STAFF) {
        redirectToApp();
        return;
      }

      setLoginError(null);
      setAuthToken(auth_token);
      setIsProcessing(false);
      setIsSuccess(true);

      const urlParts = (redirectUrl ?? OPS_HOME).split('?');
      router.push({
        pathname: urlParts[0],
        query: urlParts.length > 1 ? queryStringToObject(urlParts[1]) : null,
      });
      return;
    } catch (error) {
      // error: Response
      redirectIfMaintenanceMode(error).catch(()=>{})
      setLoginError("Didn't work. Try different details?");
      console.error('something went wrong', error);
    }
    setIsProcessing(false);
    setIsSuccess(false);
  };

  const handleOnChange = event => {
    setValue(event.target.name, event.target.value);
    clearErrors(event.target.name);
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (errors && Object.entries(errors).length !== 0) {
      console.error(errors);
    }
  }, [errors]);

  return (
    <Box width="100%">
      {isSuccess && (
        <Flex
          minWidth={`${formDimensions.width}px`}
          minHeight={`${formDimensions.height}px`}
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          backgroundColor="main.white"
          borderRadius="md"
          pl={4}
        >
          <Flex flexDirection="row" alignItems="center">
            <CheckCircleIcon color="green.400" mr={2} />
            <Text fontWeight="bold" fontSize="sm" color="text.primary">
              {'Alright, alright!'}
            </Text>
          </Flex>
          <Flex flexDirection="row" alignItems="center">
            <Text fontSize="sm" color="text.primary">
              {'Hang tight, redirecting you...'}
            </Text>
            <Spinner width=".75rem" height=".75rem" ml={2} />
          </Flex>
        </Flex>
      )}
      {!isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <FormControl mb={4}>
            <Input
              {...register('email', { required: 'Please include an email.' })}
              placeholder="Email"
              id="email"
              onChange={handleOnChange}
              size="lg"
              backgroundColor="navy.50"
              borderColor="navy.100"
              _placeholder={{ color: 'gray.300' }}
            />
            {errors.email ? (
              <FormErrorMessage>{`${errors.email.message}`}</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl mb={4}>
            <InputGroup>
              <Input
                {...register('password', {
                  required: 'Please include a password.',
                })}
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                onChange={handleOnChange}
                size="lg"
                backgroundColor="navy.50"
                borderColor="navy.100"
                _placeholder={{ color: 'gray.300' }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  mt={2}
                  size="sm"
                  color="gray.200"
                  backgroundColor="transparent"
                  _hover={{
                    backgroundColor: 'gray.100',
                    color: 'gray.300',
                  }}
                  _active={{
                    backgroundColor: 'gray.300',
                  }}
                  onClick={handlePasswordVisibility}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.password ? (
              <FormErrorMessage>
                {`${errors.password.message}`}
              </FormErrorMessage>
            ) : null}
          </FormControl>
          {loginError && (
            <Text fontSize="sm" color="red.500" mb={4} ml={2}>
              {loginError}
            </Text>
          )}
          <Flex flexDirection="column" gap={4}>
            <Button
              type="submit"
              isLoading={formState.isSubmitting || isProcessing}
              isDisabled={isSuccess}
              size="lg"
              variant="pink"
              alignSelf="start"
            >
              {'Log In'}
            </Button>
            <a href={`${WEB_APP_ROOT}/app`}>
              <Button
                as="span"
                variant="link"
                onClick={() => null}
                leftIcon={<Icon name={ICON_NAMES.externallinkalt} />}
              >
                <Text fontWeight="medium">{'Web App'}</Text>
              </Button>
            </a>
          </Flex>
        </form>
      )}
    </Box>
  );
};
